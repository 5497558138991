@use "../config" as *;
.waf-search {
  padding: 0 0 var(--space-14);
  margin: 0 var(--space-2-neg);
  @extend %bg-neutral-100;
  @extend %common-input-swiper-btn;
  @extend %tabs-underline;
  &::before {
    content: "";
    height: 64.5rem;
    background: url(/static-assets/images/cssimages/pattern/search-results-bg-m.png?v=#{$image-version}) center / cover no-repeat;
    pointer-events: none;
    @include position-combo("tl");
    @extend %w-100;
  }
  .layout-wrapper {
    @include large-wrapper();
    > .waf {
      &-head {
        padding-top: 13rem;
        margin-bottom: var(--space-6);
        @extend %relative;
      }
      &-body {
        @extend %py-6;
        @extend %pl-4;
        @extend %border-radius-m;
        @extend %relative;
        @extend %bg-neutral-0;
      }
    }
  }
  .head-wrap {
    @include default-wrapper();
    .title {
      max-width: unset;
      letter-spacing: -.05rem;
      @extend %mb-2;
      @extend %neutral-0;
      @extend %title-40;
    }
  }
  .loadmore-wrap {
    @extend %flex-c-c;
    @extend %mt-4;
  }
  .btn-loadmore {
    @extend %capitalize;
    @extend %btn-fill;
  }
  .highlight {
    background-color: transparent;
    color: inherit;
  }
  .desc {
    @extend %neutral-0;
    @extend %text-s;
  }
  .site-search-form {
    @extend %mr-4;
    @extend %mb-6;
    .search-input {
      padding-right: var(--space-9);
    }
  }
  .btn-close {
    right: var(--space-3);
    .btn-text::before {
      font-size: 2rem;
    }
  }
  .filter-section {
    width: fit-content;
    @include position(calc(var(--space-6) + 6.9rem), null, null, var(--space-4));
    @extend %px-0;
  }
  .search-results-tabs {
    margin-bottom: 14rem;
    @extend %mr-4;
  }
  .multimedia {
    @include article-listing(overlay);
  }
  .tab-container {
    &-item {
      &:not(:last-child) {
        margin-bottom: 10rem;
      }
      &.multimedia {
        .title::before {
          @include icon(play, 24);
        }
      }
      &.active {
        .card-list,
        .article-list {
          @extend %pr-4;
          @include card-count(1, var(--space-4), wrap);
        }
        .card-item,
        .article-item {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
      .title {
        @extend %mb-0;
        @extend %relative;
        @extend %neutral-100;
        @extend %title-60;
        &::before {
          color: var(--neutral-40);
          font: normal 2.4rem/1 $font-icon;
          @include position(var(--space-12-neg), null, null, 0);
          @extend %mb-0;
        }
      }
    }
    .waf-head {
      @extend %mb-2;
      @extend %flex-sb-c;
    }
    .head-tab {
      position: static;
      @extend %mx-0;
      a {
        gap: var(--space-2);
        @extend %btn-default;
        &::after {
          font-weight: normal;
          @include icon(arrow-right, 22);
        }
      }
    }
  }
  .events .card-list {
    @extend %mr-0;
  }
  .news {
    @include article-listing(card);
  }
  .news,
  .multimedia {
    .article-list {
      grid-auto-flow: column;
      grid-auto-columns: calc(100% - 24px);
      grid-template-columns: unset;
      overflow-x: auto;
    }
  }
  .waf-event-strip .card-item {
    box-shadow: unset;
  }
  .waf-athelete {
    .card {
      &-list {
        gap: var(--space-4);
        overflow-x: auto;
        @extend %flex;
      }
      &-item {
        max-width: 33rem;
        height: 39.6rem;
        border: .1rem solid var(--neutral-20);
        border-radius: 1.6rem;
        flex-basis: calc(100% - var(--space-6));
        flex-shrink: 0;
        @extend %relative;
        &:hover .card-image {
          scale: 1.05;
        }
        &.women .card-logo {
          border: .4rem solid var(--secondary-70);
        }
      }
      &-thumbnail {
        border-top-left-radius: 1.6rem;
        border-top-right-radius: 1.6rem;
        overflow: hidden;
        @extend %h-50;
        @extend %relative;
        @extend %bg-primary-50;
        &::after {
          content: "";
          position: absolute;
          inset: 0;
        }
      }
      &-image {
        aspect-ratio: 1;
        transition: scale .3s ease-in;
        object-fit: cover;
        margin: 0;
        width: 100%;
      }
      &-body {
        padding: var(--space-12) var(--space-6) var(--space-6);
        @extend %h-50;
        @extend %flex-column;
        @extend %relative;
      }
      &-logo {
        width: 9.6rem;
        height: 9.6rem;
        border: .4rem solid var(--primary-50);
        translate: 0 -50%;
        padding: var(--space-0);
        overflow: hidden;
        @include position(0, null, null, var(--space-6));
        @extend %circle-radius;
        @extend %bg-neutral-0;
        img[src^="/static-assets/images/svg/vector.svg"] {
          filter: invert(70%) sepia(96%) saturate(700%) hue-rotate(200deg) brightness(80%) contrast(250%);
        }
      }
      &-title {
        margin-block: var(--space-2) 0;
        @include truncate-text(2);
        @extend %title-40;
      }
      &-origin {
        gap: var(--space-2);
        @extend %mt-auto;
        @extend %flex-n-c;
        img {
          width: 2.4rem;
          min-width: 2.4rem;
          aspect-ratio: 1;
          object-fit: cover;
          @extend %circle-radius;
        }
      }
      &-info {
        line-height: 1;
        @extend %ml-auto;
        @extend %neutral-70;
      }
      &-action {
        @extend %position-t-l;
        @extend %w-100;
        @extend %h-100;
        .btn-site {
          @extend %w-100;
          @extend %h-100;
          @extend %d-block;
          .text {
            @extend %font-0;
          }
        }
      }
    }
  }
  .athelete {
    .title::before {
      @include icon(face-smile, 24);
    }
  }
  .news {
    .title::before {
      @include icon(newspaper, 24);
    }
  }
  .documents {
    .title::before {
      @include icon(document, 24);
    }
  }
  .web-pages {
    .title::before {
      @include icon(globe, 24);
    }
  }
  .federation {
    .title::before {
      @include icon(flag, 24);
    }
  }
  @include search-page-common();
}
@include mq(col-tablet) {
  .waf-search {
    padding-bottom: 8rem;
    &::before {
      height: unset;
      aspect-ratio: 2.23;
      background-image: url(/static-assets/images/cssimages/pattern/search-results-bg-d.png?v=#{$image-version});
    }
    .layout-wrapper {
      > .waf {
        &-head {
          padding-top: 23.3rem;
          margin-bottom: var(--space-14);
        }
        &-body {
          padding: var(--space-8) calc(var(--container-white-space) / 2);
        }
      }
    }
    .head-wrap {
      @include default-wrapper();
      .title {
        font-size: 3.2rem;
        line-height: 1.3;
      }
    }
    .desc {
      font-size: 1.4rem;
    }
    .tab-list{
      overflow-x: auto;
    }
    .site-search-form {
      margin-inline: 0;
      .search-input {
        padding-block: var(--space-7);
      }
    }
    .filter-section {
      top: calc(var(--space-8) + 0.6rem);
      right: calc(var(--container-white-space) /2);
      left: unset;
    }
    .search-results-tabs {
      width: calc(100% - 11.5rem - var(--space-5));
      margin-bottom: 7.2rem;
      margin-left: 0;
      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }
    }
    .tab-container {
      margin-left: 0;
      .waf-head {
        margin-bottom: var(--space-5);
      }
      &-item {
        &:not(:last-child) {
          margin-bottom: 7.2rem;
        }
        &.active {
          .card-list,
          .article-list {
            gap: var(--space-6);
          }
          .card-item,
          .article-item {
            max-width: unset;
            flex-basis: calc(100% / 3 - 1.6rem);
          }
        }
        .title {
          font-size: 2.4rem;
          gap: var(--space-3);
          @include flex-config(flex, null, null, center);
          &::before {
            position: static;
          }
        }
      }
    }
    .news,
    .multimedia {
      .article-list {
        grid-auto-flow: unset;
        grid-auto-columns: unset;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        overflow-x: unset;
      }
    }
    .waf-athelete {
      .card {
        &-list {
          gap: var(--space-6);
          flex-wrap: wrap;
          overflow-x: unset;
        }
        &-item {
          max-width: unset;
          flex-basis: calc(100% / 3 - 1.6rem);
        }
      }
    }
  }
}
@include mq(col-desktop) {
  .waf-search .waf-athelete .card-item {
    flex-basis: calc(25% - 1.8rem);
  }
  .waf-search {
    .tab-container-item {
      &.active {
        .card-item,
        .article-item {
          flex-basis: calc(25% - 1.8rem);
        }
      }
    }
  }
}